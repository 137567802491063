
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>


    <b-tabs
      active-nav-item-class="nav nav-tabs"
      content-class="mt-3"
    >
      <b-tab title="Accrual" active>
        <div class="row">
          <div class="col-md-12">
            <h4>Filters:</h4>
          </div>
        </div>

        <div class="row" style="margin-bottom: 20px;">

          <div class="col-md-2">
            <div>
              By Outlet:
            </div>
            <select class="form-control" v-model="outlet" @change="getAccrualMerchantInvoices">
              <option v-if="role != 'Merchant'" value="" > All </option>
              <option :value="shop" v-for="shop in outlets"> {{ shop.name }}</option>
            </select>
          </div>
          <div class="col-md-5">
            <div>
              By date:
            </div>
            <date-picker   :lang="lang" v-model="start_date" valueType="format" :first-day-of-week="1"></date-picker> to
            <date-picker   :lang="lang" v-model="end_date" valueType="format" :first-day-of-week="1"></date-picker> &nbsp;&nbsp;
            <button type="button" class="btn btn-danger" @click="getAccrualMerchantInvoices">Filter</button>
          </div>
          <div class="col-md-5">
          </div>
        </div>

        <b-table striped hover :items="invoices" :fields="columns">

          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(invoiceNum)="data">
            <a style="text-decoration: underline; font-weight: bolder;" :href="'/apps/invoice/'+data.item.id">{{ data.value }}</a>
          </template>

          <template v-slot:cell(outlet)="data">
            <span>{{ data.value.name }}</span>
          </template>
          <template v-slot:cell(invoiceAmount)="data">
            <span>KES. {{ data.value | number('0,0') }}</span>
          </template>

          <template v-slot:cell(duration)="data">
            {{ data.item.invoiceStartDate }} - {{ data.item.invoiceEndDate }}
          </template>

          <template v-slot:cell(transactionType)="data">
            <span v-if="data.item.outlet && data.item.outlet.netsuiteId">{{ data.item.outlet.netsuiteId }}</span>
          </template>

          <template v-slot:cell(options)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown text="options">
              <b-dropdown-item href="#" @click="openResendInvoiceModal(data.item)">Resend</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="row">
          <div class="col-md-12">
            Total: KES. {{ accrualTotal | number('0,0') }}
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">

            <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
              <ul class="pagination justify-content-center">
                <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
                <li v-for="page in pagesNumber"
                    v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>

      </b-tab>
      <b-tab title="Redemption">
        <div class="row">
          <div class="col-md-12">
            <h4>Filters:</h4>
          </div>
        </div>

        <div class="row" style="margin-bottom: 20px;">

          <div class="col-md-3">
            <div>
              By Outlet:
            </div>
            <select class="form-control" v-model="outlet" @change="getRedemptionMerchantInvoices">
              <option v-if="role != 'Merchant'" value="" > All </option>
              <option :value="shop" v-for="shop in outlets"> {{ shop.name }}</option>
            </select>
          </div>
          <div class="col-md-6">
            <div>
              By date:
            </div>

            <date-picker   :lang="lang" v-model="start_date" valueType="format" :first-day-of-week="1"></date-picker> to
            <date-picker   :lang="lang" v-model="end_date" valueType="format" :first-day-of-week="1"></date-picker> &nbsp;&nbsp;
            <button type="button" class="btn btn-danger" @click="getRedemptionMerchantInvoices">Filter</button>

          </div>
        </div>

        <b-table striped hover :items="redemption_invoices" :fields="columns">

          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(invoiceNum)="data">
            <a style="text-decoration: underline; font-weight: bolder;" :href="'/app/pages/invoice/'+data.item.id">{{ data.value }}</a>
          </template>

          <template v-slot:cell(outlet)="data">
            <span>{{ data.value.name }}</span>
          </template>
          <template v-slot:cell(invoiceAmount)="data">
            <span>KES. {{ data.value | number('0,0') }}</span>
          </template>

          <template v-slot:cell(duration)="data">
            {{ data.item.invoiceStartDate }} - {{ data.item.invoiceEndDate }}
          </template>


          <template v-slot:cell(options)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown text="options">
              <b-dropdown-item href="#" @click="openResendInvoiceModal(data.item)">Resend</b-dropdown-item>
              <!--<b-dropdown-item href="#" @click="openReverseTransactionModal(data.item)">Reverse</b-dropdown-item>-->
            </b-dropdown>
          </template>
        </b-table>

        <div class="row">
          <div class="col-md-12">
            Total: KES. {{ redemptionTotal | number('0,0') }}
          </div>
        </div>

      </b-tab>
    </b-tabs>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Multiselect from 'vue-multiselect'

export default {
  data() {
    return {
      disabled:false,
      isLoading: false,
      fullPage: true,
      merchantId:"",
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },

      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      offset: 4,
      columns:["no","invoiceNum","invoiceDate","outlet","duration","invoiceAmount", {
        key: 'transactionType',
        label: 'Netsuite ID',
        sortable: false
      },"options"],
      invoices:[],
      invoice:{id:0},
      start_date:"all",
      end_date:"all",
      outlet:null,
      outlets:[],
      redemption_invoices:[],
      transactionType:""
    };
  },
  computed:{

    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },

    permissions(){
      return localStorage.getItem("permissions");
    },
    role(){
      return localStorage.getItem("role");
    },
    accrualTotal(){
      return this.invoices.reduce(function (acc, invoice) { return acc + invoice.invoiceAmount; }, 0)
    },
    redemptionTotal(){
      return this.redemption_invoices.reduce(function (acc, invoice) { return acc + invoice.invoiceAmount; }, 0)
    }
  },
  components: {
    Loading,
    DatePicker,
    Multiselect
  },
  mounted (){

    this.getOutletByMerchant();

  },
  methods: {
    ...mapActions(["resendNetsuiteInvoice","exportInvoices","generateBulkInvoice","clearInvoices","getOutletsByUserId","getInvoiceByOutletId","getInvoices","getOutlets","generateAllMerchantInvoiceForThePreviousMonth"]),
    changePage: function (page) {
      this.pagination.current_page = page;
      this.getAccrualMerchantInvoices();
    },
    getOutletData(){
      let self = this;

      this.isLoading = true

      this.getOutlets()
        .then(function (outlets) {

          self.outlets = outlets;

        })
        .catch(function (error) {
          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },

    generateBulkInvoiceData(){
      let self = this;

      this.isLoading = true;
      this.generateBulkInvoice({transactionType: this.transactionType})
          .then(function () {

            self.isLoading = false;

            self.$bvToast.toast("Invoice generated successfully.", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

            self.getAccrualMerchantInvoices();


          })
          .catch(function (error) {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });
          })
    },

    generateOutletInvoice(){
      let self = this;

      this.isLoading = true;
      this.generateAllMerchantInvoiceForThePreviousMonth({id: this.merchantId, transactionType: this.transactionType})
          .then(function () {

            self.isLoading = false;

            self.$bvToast.toast("Invoice generated successfully.", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

            self.getAccrualMerchantInvoices();


          })
          .catch(function (error) {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });
          })
    },

    confirmExportInvoice(){
      let self = this;

      this.isLoading = true;
      this.exportInvoices({startDate: this.start_date, endDate: this.end_date})
          .then(function (reponse) {

            self.isLoading = false;

            location.replace(reponse.message);


          })
          .catch(function (error) {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });
          })
    },

    getOutletByMerchant(){
      let self = this;

      this.isLoading = true;
      this.getOutletsByUserId({userId: localStorage.getItem("userId")})
        .then(function (outlets) {


          self.isLoading = false;

          self.outlets = outlets;

          if(self.outlets.length > 0){
            self.outlet = self.outlets[0];
            self.getAccrualMerchantInvoices();
            self.getRedemptionMerchantInvoices();

          }


        })
        .catch(function (error) {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },

    /*filterInvoiceByMerchant(){
       if(this.outlet){
          console.log("Fetching by merchant");
          this.getInvoiceByMerchant();
       }
       else{
          this.getAccrualMerchantInvoices();
       }
    },
    getInvoiceByMerchant(){

      let self = this;

      self.isLoading = true;

      this.getInvoiceByOutletId({outletId: this.outlet.id})


        .then(function (invoices) {

          self.isLoading = false;

          self.invoices = invoices;


        })
        .catch(function (error) {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/app/sessions/signIn");
            }

          }
        })
    },*/

    getAccrualMerchantInvoices(){

      this.isLoading = true;

      let self = this;

      let outlet_id = 0;

      if(this.outlet){
        outlet_id = this.outlet.id;
      }

      this.getInvoices({outletId: outlet_id, startDate: this.start_date, endDate: this.end_date, transactionType: "Accrual", page: this.pagination.current_page})
        .then(function (invoices) {

          self.isLoading = false;

          self.invoices = invoices.content;
          self.pagination.current_page = invoices.number;
          self.pagination.total = invoices.totalElements;
          self.pagination.per_page = invoices.numberOfElements;
          self.pagination.from = invoices.pageable.offset + 1 ;
          self.pagination.to = invoices.pageable.pageSize;
          self.pagination.last_page = invoices.totalPages;






        })
        .catch(function (error) {

          self.isLoading = false;

          console.log(error);

          /*if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/app/sessions/signIn");
            }

          }*/
        })
    },

    openResendInvoiceModal(invoice){
      this.invoice = invoice;
      let self = this;
      this.$bvModal.msgBoxConfirm('Resend invoice for'+invoice.outlet.name+' to netsuite ?')
          .then(value => {

            if(value){
              self.confirmResendInvoiceToNetsuite();
            }

          })
          .catch(err => {
            // An error occurred
          })
    },

    openClearInvoicesModal(){

      let self = this;
      this.$bvModal.msgBoxConfirm('Do you want to delete all invoices ?')
          .then(value => {

            if(value){
              self.confirmDeleteInvoices();
            }

          })
          .catch(err => {
            // An error occurred
          })
    },


    confirmDeleteInvoices(){
      this.isLoading = true;

      let self = this;

      this.clearInvoices().then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Invoices deleted", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getAccrualMerchantInvoices();
        self.getRedemptionMerchantInvoices();

      })
          .catch(error => {

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })

    },

    confirmResendInvoiceToNetsuite(){
      this.isLoading = true;

      let self = this;

      this.resendNetsuiteInvoice({id: this.invoice.id}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Invoice sent to netsuite", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

      })
          .catch(error => {

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })

    },

    getRedemptionMerchantInvoices(){

      this.isLoading = true;

      let self = this;

      let outlet_id = 0;

      if(this.outlet){
        outlet_id = this.outlet.id;
      }

      this.getInvoices({outletId: outlet_id, startDate: this.start_date, endDate: this.end_date, transactionType: "Redemption", page: 0})
        .then(function (invoices) {

          self.isLoading = false;

          self.redemption_invoices = invoices.content;
         /* self.pagination.current_page = invoices.number;
          self.pagination.total = invoices.totalElements;
          self.pagination.per_page = invoices.numberOfElements;
          self.pagination.from = invoices.pageable.offset + 1 ;
          self.pagination.to = invoices.pageable.pageSize;
          self.pagination.last_page = invoices.totalPages;*/


        })
        .catch(function (error) {

          self.isLoading = false;

          console.log(error);

          /*if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/app/sessions/signIn");
            }

          }*/
        })
    },




  }
};
</script>
